import React from "react";
// Components
import { Grid, Divider } from "@mui/material";
import { InputButton } from "components/UI";
// Utils
import { messageDisplay, responseErrors } from "utils";
import { validationSearchForm } from "utils/validation";
import { transformData } from "../hooks/tranformData";
//Service
import { useExportInvoiceSearchMutation } from "shared/services/invoice";
//Type
import { DEFAULT_EXPORT_INVOICE_INFO } from "../constants/constants";
import { BUTTON_VALUE, FIRST_PAGE, PAGINATION, ROW_PER_PAGE } from "shared/constants";
import { MSG_TYPE, MessageType, ModeAction } from "state/enum";
import { userProfile } from "constant";
import { useLoadingContext } from "shared/contexts/LoadingContext";
export default function ButtonCriterial(props) {
  const {
    // mode,
    setMode,
    form,
    setForm,
    // onSearch,
    // rows,
    setRows,
    setOnSearch,
    // setShippingInfo,
    setMsg: { setMsgAlert, setMsgError },
    stateButton: { searchBtn, clearBtn },
    rowSelection: { setRowModesModel, setRowSelectionModel },
    pagination: { setPagination, setPageNumber },
    functionId,
  } = props;
  const searchExportInfo = useExportInvoiceSearchMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleSearch = async event => {
    try {
      event.preventDefault();
      setMsgAlert([]);
      setMsgError([]);
      // Reset Data of Table
      setPageNumber(FIRST_PAGE);
      setPagination({});

      setMode(ModeAction.VIEW);
      setOnSearch(false);

      setRows([]);
      setRowSelectionModel([]);
      setRowModesModel({});
      const body = {
        dataOwner: userProfile.dataOwner, // TODO: mock constants here
        importerId: form?.importerId,
        vesselName: form?.vesselName,
        invoiceNo: form?.invoiceNo,
        etdFrom: form?.etdFrom,
        etdTo: form?.etdTo,
        loginUserCompanyAbbr: userProfile.comAbbr, // TODO: mock constants here
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: "importerId",
            type: MessageType.EMPTY,
            key: ["Importer"],
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      startLoading();
      const searchData = await searchExportInfo(body);
      stopLoading();
      if (!searchData?.result?.data?.length) {
        setMsgError([
          messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0059AERR",
            msg: "",
          }),
        ]);
        return;
      }
      const data = transformData(searchData?.result?.data);
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  const handleClear = () => {
    setMsgAlert([]);
    setMsgError([]);
    setForm(DEFAULT_EXPORT_INVOICE_INFO);
    //clear local storage
    // setShippingInfo(DEFAULT_LOCAL_STATE_WDN95030);
    // Reset Data of Table
    setPageNumber(FIRST_PAGE);
    setRows([]);
    setPagination({});
    setRowSelectionModel([]);
    setRowModesModel({});
    //Reset state
    setOnSearch(false);
    setMode(ModeAction.VIEW);
  };
  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}>
        <InputButton
          id="button-search"
          value={BUTTON_VALUE.SEARCH}
          disabled={searchBtn}
          onClick={handleSearch}
          name={`${functionId}Search`}
        />
        <InputButton
          id="button-clear"
          value={BUTTON_VALUE.CLEAR}
          disabled={clearBtn}
          onClick={handleClear}
          name={`${functionId}Clear`}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}
