import React from "react";
import { AppBar, Typography } from "@mui/material";
import { userProfile } from "constant";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Height } from "@mui/icons-material";

export const HeaderContentBar = ({ title = "", isAdmin = false, setIsAdmin = undefined }) => {
  const style = {
    areaHeader: {
      display: "flex",
      justifyContent: "space-between",
      // padding: 8,
      // borderBottom: "1px solid #cec5c5",
      height: 45,
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
    bgUserNameBar: {
      backgroundColor: "#e2dfdf",
      border: "1px solid #e2dfdf",
    },
    bgTitleBar: {
      backgroundColor: "#969191",
      border: "1px solid #969191",
      color: "#ffff",
    },
    shadowNone: {
      boxShadow: "none",
    },
    appBar: {
      marginBottom: 15
    },
  };

  const styleAppBar = {
    ...style.shadowNone,
    ...style.appBar,
  };

  const styleUserBar = {
    ...style.areaHeader,
    ...style.bgUserNameBar,
  };

  const styleTitleBar = {
    ...style.areaHeader,
    ...style.bgTitleBar,
  };
  const handleRole = () => {
    if (setIsAdmin) setIsAdmin(!isAdmin);
  };

  const user = useSelector(state => state.auth.user);
  const USER = `User: ${user?.displayName ?? userProfile.displayName}`;
  const ADMIN = `Admin: ${user?.displayName ?? userProfile.displayName}`;
  // console.log(user);

  return (
    <AppBar position="static" color="secondary" style={styleAppBar} enableColorOnDark>
      <div style={styleUserBar}>
        <div style={{ color: "red", fontWeight: "bold", fontSize: "16px", margin: "auto 15px" }}>POLYGON</div>
        <Typography component="button" onClick={handleRole} sx={{ border: "none", backgroundColor: "#e2dfdf", fontSize: "16px", margin: "auto 15px" }}>
          {/* if want to show user delete color css*/}
          {isAdmin ? ADMIN : USER}
        </Typography>
      </div>
      <div style={styleTitleBar}>
        <div style={{ fontSize: "16px", margin: "auto 15px" }}>{title}</div>
        <div style={{ fontSize: "16px", margin: "auto 15px" }}>{dayjs().format("DD MMM YYYY HH:mm")}</div>
      </div>
    </AppBar>
  );
};
